<template>
  <div class="card">
    <header>
      <h3>{{ item.title }}</h3>
      <p class="maker" v-if="item.maker">By {{ item.maker }}</p>
      <p class="price">${{ item.price }} {{ item.units }}</p>
    </header>
    <div class="image">
      <img :src="item.imageFile" :alt="item.title" />
      <div v-if="sold" :class="{ sold: sold }"></div>
    </div>
    <p>{{ item.desc }}</p>
  </div>
</template>

<script>
import { computed } from 'vue';
export default {
  props: ['item'],
  setup(props) {
    // console.log(props);
    const sold = computed(() => {
      if (props.item.isSold === 'true') {
        return true;
      } else {
        return false;
      }
    });
    return { sold };
  },
};
</script>

<style scoped>
@media screen and (min-width: 700px) {
  header {
    margin-bottom: 1.5em;
  }
  .card {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  .card > p {
    margin-top: 2rem;
  }
  .image {
    border-radius: 5px;
    /* object-fit: contain; */
  }
  img {
    border-radius: 5px;
  }
}
</style>
