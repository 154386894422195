<template>
  <footer class="footer">
    <div class="contact">
      <p class="phone">813.334.6857</p>
      <p class="email">red@redsrecyclables.com</p>
    </div>
    <p class="greengeeks">Powered by Green Geeks</p>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  padding: 0.5em 1.5em;
  margin: 0 auto;
  max-width: 1200px;
  color: #eee;
  background-color: hsl(100, 68%, 9%);
}

.contact {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}
.greengeeks {
  text-align: left;
  font-size: 0.5em;
}
@media screen and (min-width: 700px) {
  .footer {
    padding: 1.5rem;
  }
  .contact {
    font-size: 1.5rem;
  }
  .greengeeks {
    font-size: 1rem;
    font-weight: 400;
    color: #eee;
    opacity: 0.5;
  }
}
</style>
