<template>
  <div class="error" v-if="error">{{ error }}</div>
  <div v-else>
    <h3>Log In</h3>
    <form @submit.prevent="handelSubmit">
      <div class="form-control">
        <label for="email">Email</label>
        <input type="email" id="email" required v-model="email" />
      </div>
      <div class="form-control">
        <label for="password">Password</label>
        <input type="password" id="password" required v-model="password" />
      </div>
      <div class="form-control">
        <button>Login</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import useLogin from '../composables/useLogin';
import router from '../router/index';
export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const { login, error } = useLogin();

    const handelSubmit = async () => {
      const res = await login(email.value, password.value);
      console.log(res.user.email);
      router.push({ name: 'Home' });
      email.value = '';
      password.value = '';
    };

    return { login, error, password, email, handelSubmit };
  },
};
</script>

<style scoped>
h3 {
  font-size: 2rem;
}
</style>
