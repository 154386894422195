<template>
  <div class="container">
    <Navbar />
    <div>
      <router-view />
    </div>
  </div>
  <BaseFooter />
</template>

<script>
import Navbar from './components/Navbar.vue';
import BaseFooter from './components/baseFooter.vue';
export default {
  components: { Navbar, BaseFooter },
};
</script>

<style>
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Poppins', sans-serif;
}
.card,
a,
.container {
  background-color: hsl(129, 29%, 95%);
}
h1 {
  font-size: 2rem;
}
h1::first-letter,
h3::first-letter {
  text-transform: capitalize;
}
select {
  font-size: 16px;
}
.image {
  width: 100%;
  padding-top: 75%;
  position: relative;
  /* max-height: 250px; */
  overflow: hidden;
  /* align-self: flex-start; */
}
.image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 100%;
}
.container {
  min-height: calc(100vh - 90px);
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}
.card {
  border-radius: 5px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.096);
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  overflow: hidden;
  cursor: pointer;
  padding: 0.5em;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* Forms */
form {
  max-width: 500px;
  margin: 1rem auto;
}
.form-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  text-align: left;
  line-height: 1.6;
  font-size: 1.2rem;
}
.form-control label {
  margin-bottom: 0.1em;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 300;
  color: hsl(0, 0%, 45%);
}
.form-control input {
  font-size: 1rem;
  padding: 0.25em;
  border: 1px solid hsl(0, 0%, 45%, 0.25);
  border-radius: 5px;
}
button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2fad55;
  padding: 8px 16px;
  margin-right: 0.5em;
  border-radius: 5px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
}
button:hover {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}
.error {
  color: red;
}
.sold::before {
  position: absolute;
  content: 'Sold';
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  color: hsla(0, 100%, 50%, 0.6);
  font-size: 4em;
  font-weight: 700;
}
@media screen and (min-width: 1200px) {
  body {
    background-color: hsl(100, 68%, 9%);
  }
}
</style>
