<template>
  <div v-if="!archived || user" class="card" :class="{ archived: archived }" @click="handelDetails">
    <header class="card-header">
      <div card-title>
        <h3 class="card-title">{{ item.title }}</h3>
        <p class="card-subtitle">By {{ item.maker || 'Not Known' }}</p>
      </div>
    </header>
    <div class="image">
      <img :src="item.imageFile" alt />
      <div v-if="sold" :class="{ sold: sold }"></div>
    </div>
    <div class="card-body">
      <p>{{ `${item.desc.substring(0, 50)}...` }}</p>
      <p class="price">{{ `$${item.price} ${item.units}` }}</p>
    </div>
    <div class="buttons" v-if="user">
      <button class="update-item" @click.stop="updateItem">Update</button>
      <button class="delete-item" @click.stop="deleteItem">Delete</button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/reactivity';
import getUser from '../composables/getUser';
import { projectFirestore } from '../firebase/config';
import router from '../router';

export default {
  props: ['item'],
  setup(props) {
    const { user } = getUser();
    const error = ref(null);

    // display based on sold or archived
    const archived = computed(() => {
      if (props.item.isArchived === 'true') {
        return true;
      } else {
        return false;
      }
    });

    const sold = computed(() => {
      if (props.item.isSold === 'true') {
        return true;
      } else {
        return false;
      }
    });

    const handelDetails = async () => {
      router.push({
        name: 'Details',
        params: { id: props.item.id },
      });
    };

    const updateItem = async () => {
      router.push({
        name: 'UpdateItem',
        params: { itemId: props.item.id },
      });
    };

    // delete item in database and image from storage

    const deleteItem = async () => {
      try {
        const delRef = projectFirestore.collection('items').doc(props.item.id);
        await delRef.delete();
        //delete storage here
        router.go(0);
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    };

    return {
      user,
      error,
      updateItem,
      deleteItem,
      archived,
      sold,
      handelDetails,
    };
  },
};
</script>

<style scoped>
.card-header,
.card-body,
.buttons,
.image {
  /* padding: none; */
  border-radius: 5px;
}
.card-body {
  align-self: flex-end;
}
.price {
  margin: 1.5em 0;
}
.archived {
  background-color: hsla(60, 100%, 50%, 0.548);
}
/* .sold::before {
  position: absolute;
  content: 'Sold';
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  color: hsla(0, 100%, 50%, 0.6);
  font-size: 4em;
  font-weight: 700;
} */
.delete-item {
  color: #2fad55 !important;
  border: 1px solid #2fad55;
  background-color: transparent;
}
</style>
