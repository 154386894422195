<template>
  <div class="container-featured">
    <h1>Featured Item's</h1>
    <p v-if="error" class="error">{{ error }}</p>
    <div class="grid-featured">
      <div v-if="items.length" class="featured1">
        <Featured :item="items[0]" />
      </div>
      <div v-if="items.length" class="featured2">
        <Featured :item="items[1]" />
      </div>
      <div v-if="items.length" class="featured3">
        <Featured :item="items[2]" />
      </div>
    </div>
  </div>
</template>

<script>
import getType from '../composables/getType';
import Featured from '../components/Featured.vue';

export default {
  components: { Featured },
  setup() {
    const { items, error, load } = getType();

    load('isFeatured');
    // console.log(items);

    return { items, error };
  },
};
</script>

<style scoped>
@media screen and (min-width: 700px) {
  .featured1,
  .featured2,
  .featured3 {
    margin-bottom: 5rem;
  }
  .container-featured {
    padding: 1rem;
  }
  .grid-featured {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 2em;
  }
  .featured1 {
    grid-area: 1/1/2/2;
  }
  .featured2 {
    grid-area: 1/2/2/3;
  }
  .featured3 {
    grid-area: 2/1/3/3;
  }
}
</style>
