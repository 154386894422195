import { ref } from 'vue';
import { projectFirestore } from '../firebase/config';
import router from '../router';

const getType = () => {
  const items = ref([]);
  const error = ref(null);
  let res = ref(null);

  const load = async (type) => {
    try {
      if (type === undefined) {
        router.push({ name: 'Home' });
      } else if (type !== 'isFeatured') {
        res.value = await projectFirestore
          .collection('items')
          .where('type', 'array-contains', type)
          .get();
      } else {
        res.value = await projectFirestore
          .collection('items')
          .where('isFeatured', '==', true)
          .get();
      }

      items.value = res.value.docs.map((doc) => {
        // console.log(doc.data());
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };

  return { items, error, load };
};

export default getType;
