<template>
  <div class="container">
    <div class="error" v-if="error">{{ error }}</div>
    <h1>{{ type || 'Furnature' }}</h1>
    <div class="items-list">
      <template v-for="item in items" :key="item.id">
        <ListItems :item="item" />
      </template>
    </div>
  </div>
</template>

<script>
import ListItems from '../components/ListItems.vue';
import getType from '../composables/getType';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { ref } from '@vue/reactivity';

export default {
  components: { ListItems },

  setup() {
    const route = useRoute();
    const type = ref(route.params.type);
    const { items, error, load } = getType();

    onBeforeRouteUpdate((to) => {
      type.value = to.params.type;
      load(type.value);
      console.log('before' + type.value);
    });
    console.log(route.params.type);
    load(type.value);

    const handelError = () => {
      setTimeout(() => {
        console.log('settimeout');
      }, 3000);
    };

    return { items, error, type, handelError };
  },
};
</script>

<style scoped>
h3::first-letter {
  text-transform: uppercase;
}
</style>
