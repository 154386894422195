<template>
  <div>
    <h1>Add</h1>
    <Form :operation="'add'" />
  </div>
</template>

<script>
import Form from '../components/ItemForm.vue';
// import { useRoute } from 'vue-router';

export default {
  components: { Form },

  setup() {
    // const route = useRoute();
    // const id = route.params.itemId;
    // return { id };
  },
};
</script>

<style></style>
