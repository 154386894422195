<template>
  <div>
    <h1>{{ item.title }}</h1>
    <div class="card">
      <p class="card-subtitle" v-if="item.maker">By {{ item.maker }}</p>
      <div class="image">
        <img :src="item.imageFile" :alt="item.title" />
        <div v-if="sold" :class="{ sold: sold }"></div>
      </div>
      <p>{{ item.desc }}</p>
      <p class="price">${{ item.price }} {{ item.unit }}</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
export default {
  props: ['item'],
  setup(props) {
    const sold = computed(() => {
      if (props.item.isSold === 'true') {
        return true;
      } else {
        return false;
      }
    });
    return { sold };
  },
};
</script>

<style scoped>
section,
main,
p,
div,
img {
  margin-bottom: 0.5em;
}
p {
  line-height: 1.6;
}
h1 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0;
}
.contact {
  display: flex;
  justify-content: space-between;
}
.email,
.phone {
  padding: 0.25em;
}
</style>
