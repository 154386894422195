import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDvG_Xk5a6O473ieTN8CztTZGCM3_SnR8s',
  authDomain: 'online-sales-e05b8.firebaseapp.com',
  projectId: 'online-sales-e05b8',
  storageBucket: 'online-sales-e05b8.appspot.com',
  messagingSenderId: '723278278959',
  appId: '1:723278278959:web:098b850d004cbeafb9b1da',
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init firestore service
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

export { projectFirestore, projectAuth, projectStorage };
