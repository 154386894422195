<template>
  <DetailedItem :item="item" />
</template>

<script>
import { useRoute } from 'vue-router';
import DetailedItem from '../components/DetailedItem.vue';
import getItem from '../composables/getItem';

export default {
  components: { DetailedItem },
  setup() {
    // get items ckick by id
    const route = useRoute();
    const id = route.params.id;
    const { load, item } = getItem();

    load(id);

    return { item };
  },
  // use component listitems to display card OR
  // new component to list single item that alao incorporates contact info
  // need all type
};
</script>

<style></style>
