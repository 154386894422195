import { ref } from 'vue';
import { projectFirestore } from '../firebase/config';
import { projectStorage } from '../firebase/config';
import router from '../router';

const useItem = () => {
  const error = ref(null);
  const addedItemId = ref(null);
  const url = ref(null);

  // Add a new item
  const addData = async (item) => {
    error.value = null;

    try {
      const res = await projectFirestore.collection('items').add(item);
      addedItemId.value = res.id;
      console.log('Item added, ID: ', res.id);
      return res;
    } catch (err) {
      console.log(err.message);
      error.value = 'could not add item';
    }
  };

  // Update Item
  const updateData = async (data, itemId) => {
    error.value = null;

    try {
      const updateRef = projectFirestore.collection('items').doc(itemId);
      await updateRef.update(data);
      router.replace({
        name: 'Home',
      });
    } catch (err) {
      console.log(err.message);
      error.value = 'could not update item';
    }
  };

  // Store file
  const storeFile = async (file, id) => {
    if (id) {
      addedItemId.value = id;
    }
    if (file) {
      // const path = `images/${addedItemId.value}/${file.name}`;
      const path = `images/${addedItemId.value}/${id}.${
        file.type === 'image/jpeg' ? 'jpg' : 'png'
      }`;
      const storageRef = projectStorage.ref(path);

      try {
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    }
  };

  const deleteData = async () => {
    console.log('Delete');
  };

  return {
    error,
    addData,
    updateData,
    deleteData,
    storeFile,
    url,
    addedItemId,
  };
};

export default useItem;
