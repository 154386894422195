<template>
  <div>
    <h1>Update</h1>
    <Form :operation="'update'" :itemId="id" />
  </div>
</template>

<script>
import Form from '../components/ItemForm.vue';
import { useRoute } from 'vue-router';

export default {
  components: { Form },

  setup() {
    const route = useRoute();
    const id = route.params.itemId;

    return { id };
  },
};
</script>

<style></style>
