import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Featured from '../views/Featured.vue';
import TypeItem from '../views/TypeItem.vue';
import Details from '../views/Details.vue';
import Add from '../views/Add';
import Update from '../views/Update';
import Login from '../components/LoginForm.vue';

import { projectAuth } from '../firebase/config';

const authRequired = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (user) {
    next();
  } else {
    next({ name: 'Home' });
  }
};

const routes = [
  {
    path: '/',
    name: 'Featured',
    component: Featured,
    props: true,
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/featured',
  //   name: 'Featured',
  //   component: Featured,
  //   props: true,
  // },
  {
    path: '/details/:id',
    name: 'Details',
    component: Details,
    props: true,
  },
  {
    path: '/types:type',
    name: 'Types',
    component: TypeItem,
    props: true,
  },
  {
    path: '/add',
    name: 'Add',
    component: Add,
    props: true,
    beforeEnter: authRequired,
  },
  {
    path: '/update-item',
    name: 'UpdateItem',
    component: Update,
    props: true,
    beforeEnter: authRequired,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:notFound(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.replace({ name: 'Featured' });

export default router;
