<template>
  <div>
    <form>
      <div class="form-control">
        <label for="title">Title</label>
        <input
          :class="{ error: valTitle }"
          type="text"
          id="title"
          required
          v-model.trim="title"
        />
        <p v-if="valTitle" class="error">Title is required.</p>
      </div>
      <div class="form-control">
        <label for="maker">Manufacturer</label>
        <input type="text" id="maker" v-model.trim="maker" />
      </div>
      <div class="form-control">
        <label for="piece-type">Piece Type</label>
        <select
          :class="{ error: valType }"
          id="piece-type"
          required
          multiple
          size="5"
          v-model="pieceType"
        >
          <option value="furnature">furnature</option>
          <option value="ephemeral">ephemeral</option>
          <option value="decor">decor</option>
          <option value="wall art">wall art</option>
          <option value="outdoor">outdoor</option>
        </select>
        <p v-if="valType" class="error">Type is required.</p>
      </div>
      <div class="form-control">
        <label for="description">Description</label>
        <textarea
          :class="{ error: valDesc }"
          type="text"
          id="description"
          required
          v-model="description"
          rows="5"
        ></textarea>
        <p v-if="valDesc" class="error">Description required</p>
      </div>
      <div class="form-control">
        <label for="amount">Amount</label>
        <input type="number" id="amount" v-model.number="amount" />
      </div>
      <div class="form-control">
        <label for="units">Units</label>
        <select id="units" required v-model="units">
          <option value>--Select Units--</option>
          <option value="each">each</option>
          <option value="set">set</option>
          <option value="group">group</option>
        </select>
      </div>
      <div class="form-control">
        <p>Is this item featured?</p>
        <div class="radio-flex">
          <div>
            <label for="featured-yes">yes</label>
            <input
              type="radio"
              id="featured-yes"
              value="true"
              v-model="featured"
            />
          </div>
          <div>
            <label for="featured-no">no</label>
            <input
              type="radio"
              id="featured-no"
              value="false"
              v-model="featured"
            />
          </div>
        </div>
      </div>
      <div class="form-control">
        <p>Is this item sold?</p>
        <div class="radio-flex">
          <div>
            <label for="sold-yes">yes</label>
            <input type="radio" id="sold-yes" value="true" v-model="sold" />
          </div>
          <div>
            <label for="sold-no">no</label>
            <input type="radio" id="sold-no" value="false" v-model="sold" />
          </div>
        </div>
      </div>
      <div class="form-control">
        <p>Archive Item</p>
        <div class="radio-flex">
          <div>
            <label for="archive-yes">yes</label>
            <input
              type="radio"
              id="archive-yes"
              value="true"
              v-model="archive"
            />
          </div>
          <div>
            <label for="archive-no">no</label>
            <input
              type="radio"
              id="archive-no"
              value="false"
              v-model="archive"
            />
          </div>
        </div>
      </div>
      <div class="form-control">
        <label for="price">price</label>
        <input
          :class="{ error: valPrice }"
          type="number"
          id="price"
          v-model.number="price"
        />
        <p v-if="valPrice" class="error">Price required.</p>
      </div>
      <div class="form-control">
        <label for="img">Image</label>
        <input type="file" id="img" @change="handelFile" />
      </div>
      <div v-if="fileError" class="error">{{ fileError }}</div>
      <div v-if="operation === 'add'" class="form-control">
        <button type="button" @click="handelAdd">Add Item</button>
      </div>
      <div v-if="operation === 'update'" class="form-control">
        <button type="button" @click="handelUpdate">Update Item</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import getItem from '../composables/getItem';
import validateForm from '../composables/validateForm';
import useItem from '../composables/useItem';
import router from '../router';

export default {
  props: ['operation', 'itemId'],
  setup(props) {
    const title = ref('');
    const maker = ref('');
    const pieceType = ref([]);
    const description = ref('');
    const amount = ref(1);
    const units = ref('');
    const featured = ref(false);
    const sold = ref(false);
    const archive = ref(false);
    const price = ref(null);
    const imageFile = ref('');
    const file = ref(null);
    const fileError = ref(null);
    let valRes = ref([]);

    ////make new item ////
    const makeData = () => {
      const newItem = {
        title: title.value,
        maker: maker.value,
        type: pieceType.value,
        desc: description.value,
        amount: amount.value,
        units: units.value,
        isFeatured: featured.value,
        isSold: sold.value,
        isArchived: archive.value,
        price: price.value,
        imageFile: imageFile.value,
      };
      return newItem;
    };

    ///////////////////////////Control error display/////////////////////
    const valTitle = computed(() => {
      return valRes.value.includes('title');
    });
    const valType = computed(() => {
      return valRes.value.includes('type');
    });
    const valDesc = computed(() => {
      return valRes.value.includes('desc');
    });
    const valPrice = computed(() => {
      return valRes.value.includes('price');
    });

    /////////////////////////////Get image to store///////////////

    // allowed file types
    const imageTypes = ['image/jpeg', 'image/png'];
    const handelFile = async (e) => {
      const selected = e.target.files[0];
      if (selected && imageTypes.includes(selected.type)) {
        file.value = selected;
        console.log(file.value);
        fileError.value = null;
      } else {
        file.value = null;
        fileError.value = 'Please select an image file.';
      }
    };

    ////////////////////////////Add Item//////////////////
    const { addData, updateData, error, storeFile, url, addedItemId } =
      useItem();

    const handelAdd = async () => {
      valRes.value = [];
      const newItemData = makeData();
      valRes.value = await validateForm(newItemData);
      if (!valRes.value.length) {
        const res = await addData(newItemData);
        await storeFile(file.value, res.id);
        await updateData(
          { ...makeData(), imageFile: url.value },
          addedItemId.value
        );

        router.replace({
          name: 'Home',
        });
      } else {
        console.log(valRes.value);
      }
      if (error !== null) {
        console.log(error.value);
      }
    };

    ////////////////Update/////////////////////
    // fills in form for update operation
    const { load, item } = getItem();

    const fetchItem = async () => {
      if (props.itemId) {
        await load(props.itemId);
        title.value = item.value.title;
        maker.value = item.value.maker;
        pieceType.value = item.value.type;
        description.value = item.value.desc;
        amount.value = item.value.amount;
        units.value = item.value.units;
        featured.value = item.value.isFeatured;
        sold.value = item.value.isSold;
        archive.value = item.value.isArchived;
        price.value = item.value.price;
        imageFile.value = item.value.imageFile;
      }
    };
    fetchItem();

    // Handle click Update code here
    const handelUpdate = async () => {
      valRes.value = [];
      const newItemData = makeData();
      valRes.value = await validateForm(newItemData);
      if (!valRes.value.length) {
        await storeFile(file.value, props.itemId);
        await updateData(
          { ...newItemData, imageFile: url.value || newItemData.imageFile },
          props.itemId
        );
        console.log('item updated');
      }
    };

    return {
      title,
      maker,
      pieceType,
      description,
      amount,
      units,
      featured,
      sold,
      archive,
      price,
      handelAdd,
      handelUpdate,
      handelFile,
      fileError,
      url,
      valRes,
      valTitle,
      valType,
      valDesc,
      valPrice,
    };
  },
};
</script>

<style scoped>
input.error,
select.error {
  border: 1px solid red;
}
select.error,
input.error {
  color: initial;
}
</style>
