<template>
  <div class="home">
    <h1 @click.ctrl.alt.exact="secretlogin">All Items</h1>
    <div class="items-list">
      <template v-for="item in items" :key="item.id">
        <ListItems :item="item" />
      </template>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import getItems from '../composables/getItems.js';
import ListItems from '../components/ListItems.vue';
import router from '../router/index';

export default {
  name: 'Home',
  components: { ListItems },
  setup() {
    const { items, error, load } = getItems();

    load();

    const secretlogin = () => {
      router.push({ name: 'Login' });
    };
    return { items, error, secretlogin };
  },
};
</script>
<style>
h1 {
  color: rgb(50, 170, 50);
  margin: 1rem 0;
  font-size: 1.75rem;
  font-weight: 400;
}
h3 {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}
.price {
  font-weight: 700;
}
.items-list {
  display: grid;
  place-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  gap: 1em;
}
</style>
