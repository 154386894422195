const { ref } = require('vue');

const errors = ref([]);

const validateForm = (newItem) => {
  errors.value = [];
  if (!newItem.title) {
    errors.value.push('title');
  }
  if (!newItem.type.length) {
    errors.value.push('type');
  }
  if (!newItem.desc) {
    errors.value.push('desc');
  }
  if (!newItem.price) {
    errors.value.push('price');
  }
  return errors.value;
};

export default validateForm;
