import { ref } from 'vue';
import { projectAuth } from '../firebase/config';
import router from '../router/index';

const error = ref(null);

const login = async (email, password) => {
  error.value = null;

  try {
    const res = await projectAuth.signInWithEmailAndPassword(email, password);
    if (!res) {
      throw new Error('Problem logging in');
    }
    error.value = null;

    return res;
  } catch (err) {
    console.log(err.message);
    error.value = err.message;
  }
};

const logout = async () => {
  try {
    await projectAuth.signOut();
    router.push({ name: 'Home' });
  } catch (err) {
    console.log(err.message);
    error.value = err.message;
  }
};

const useLogin = () => {
  return { error, login, logout };
};

export default useLogin;
