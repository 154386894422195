import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { projectAuth } from './firebase/config';
let app;

//waits to get auth status pior to mount and only mounts if app is undefined
projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);

    app.use(router);
    app.mount('#app');
  }
});
