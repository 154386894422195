<template>
  <div id="nav">
    <div class="logo-container">
      <div class="logo">Red's Recyclables</div>
      <p class="moto">Striving to keep what is here useable</p>
    </div>
    <div v-if="user" class="nav-auth-links">
      <router-link to="/add">Add Item</router-link>
      <button @click="signout">Signout</button>
    </div>
    <div class="nav-links">
      <router-link to="/home">Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/">Featured</router-link>
    </div>
  </div>
  <div class="select-div">
    <select v-model="selected" @change="handelChange">
      <option value="null" disabled selected>Select a Category</option>
      <option value="furnature">Furnature</option>
      <option value="ephemeral">Ephemeral</option>
      <option value="decor">Decor</option>
      <option value="wall art">Wall Art</option>
      <option value="outdoor">Outdoor</option>
    </select>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import getUser from '../composables/getUser';
import useLogin from '../composables/useLogin';
import router from '../router/index';

export default {
  setup() {
    const { user } = getUser();
    const { logout } = useLogin();
    const selected = ref(null);

    const handelChange = async () => {
      router.push({ name: 'Types', params: { type: selected.value } });
      selected.value = null;
    };

    const signout = () => {
      logout();
    };

    return { user, logout, signout, selected, handelChange };
  },
};
</script>

<style scoped>
#nav {
  padding: 4px 8px;
  box-shadow: none;
  border-bottom: 1px solid hsl(0, 0%, 93%);
}
.logo-container {
  margin-bottom: 0.75em;
  text-align: left;
}
.logo {
  font-weight: 500;
}
.moto {
  color: rgb(18, 145, 18);
  font-size: 0.65em;
}
a {
  font-size: 0.75em;
  padding: 4px 8px;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
}
a:hover {
  background-color: transparent;
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}
.select-div {
  padding: 8px 0 1em;
  box-shadow: 0.5em 0.5em 1em hsla(0, 0%, 0%, 0.03);
}
select {
  color: hsl(0, 0%, 93%);
  border: none;
  outline-color: transparent;
  height: 26px;
  border-radius: 3px;
  padding: 3px 6px;
  background-color: hsl(120, 55%, 43%);
}
@media screen and (min-width: 700px) {
  #nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
  }
  .logo {
    font-size: 2em;
  }
  .moto {
    font-size: 1em;
  }
}
</style>
