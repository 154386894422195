import { ref } from 'vue';
import { projectFirestore } from '../firebase/config';

const getItem = () => {
  const item = ref({});
  const error = ref(null);

  const load = async (id) => {
    try {
      const res = await projectFirestore.collection('items').doc(id).get();
      console.log(res.data());
      item.value = res.data();
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };
  return { load, item, error };
};

export default getItem;
